<template>
  <div class="social--buttons">
    <div class="google--btn btn mr-4" @click="loginSocial('google')">
      <img src="@/assets/img/icons/google.svg" />
      <span>Gmail</span>
    </div>
    <div class="facebook--btn btn" @click="loginSocial('facebook')">
      <img src="@/assets/img/icons/facebook.svg" />
      <span>Facebook</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'social-media-auth',
  props: ['afterAction'],
  methods: {
    async loginSocial(provider) {
      try {
        this.$emit('updateLoading', true);
        let token;
        if (provider === 'facebook') {
          token = await this.$store.dispatch('v2/auth/signInWithFacebook');
        } else if (provider === 'google') {
          token = await this.$store.dispatch('v2/auth/signInWithGoogle');
        } else {
          // console.log("This social media is not available: ", provider);
          return;
        }
        if (token) {
          await this.$store.dispatch('global/updateToken', token);
          await this.$store.dispatch('global/fetch');
          if (this.afterAction !== null && this.afterAction !== undefined) {
            this.afterAction();
          } else {
            this.$router.go(0);
          }
        }
        // eslint-disable-next-line no-empty
      } catch (_) {
      } finally {
        this.$emit('updateLoading', false);
      }
    },
  },
};
</script>

<style scoped></style>
